import React from 'react'
import { graphql } from 'gatsby'
import _ from 'lodash'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { PageLayout } from '../layouts'
import {
  Landing,
  IndexContent,
} from '../components'
import variables from '../styles/variables'
import { filterPublishedEdges } from '../utils/gatsby'

const propTypes = {
  data: PropTypes.object.isRequired
}

export default function IndexPage({ data }) {
  const contentEdge = _.first(data.allYaml.edges)
  const content = contentEdge.node

  const { edges } = data.allMdx
  const publishedEdges = filterPublishedEdges(edges)
  const frontmatters = _.map(publishedEdges, e => e.node)
  const blogs = _.sortBy(frontmatters, blog => blog.frontmatter.date).reverse()
  const latestBlog = _.slice(blogs, 0, 3)
  const displayTopBanner = Boolean(content.bannerText)
  const topBanner = displayTopBanner ? {
    text: content.bannerText,
    url: content.bannerUrl,
  } : undefined

  const firstChild = (
    <Landing
      content={content}
    />
  )

  return (
    <PageLayout
      firstChild={firstChild}
      description={content.metaDescription}
      sharingTitle={content.title}
      navbarProps={{ background: variables.colors.oracle.dark }}
      displayTopBanner={displayTopBanner}
      topBannerProps={topBanner}
    >
      <Helmet>
        <meta name="ahrefs-site-verification" content="be29fb7c4cae3308a24d98950e5f4e1af4735c8dd6cf3a5296296e1942e5a7b2" />
        <title>{content.title}</title>
      </Helmet>
      <IndexContent data={content} latestBlog={latestBlog} />
    </PageLayout>
  )
}

IndexPage.propTypes = propTypes

export const contentQuery = graphql`
query GetContentData {
  allYaml(filter: {file: {eq: "index"}}) {
    edges {
      node {
        title
        header
        metaDescription
        tagline

        firstButtonText
        firstButtonUrl
        secondButtonText
        secondButtonUrl

        alternatingStoryImages {
          id
          header
          text
          src {
            childImageSharp {
              fluid(maxWidth: 700, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

        aitoFeaturesHeader
        aitoFeatures {
          header
          text
        }

        showcasesHeader
        showcases {
          header
          text
          url
          image
        }

        quotes {
          text
          authorName
          authorPosition
          authorImg {
            childImageSharp {
              fluid(maxWidth: 80, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

        latestBlogHeader
      }
    }
  },
  allMdx(filter: {frontmatter: { path: { regex: "^/blog/" } }}) {
    edges {
      node {
        frontmatter {
          path
          title
          introduction
          tags
          coverImgLink
          date
          publish
        }
        coverImg {
          childImageSharp {
            fluid(maxWidth: 330, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
}
`
